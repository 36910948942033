import React from 'react';
import {Helmet} from 'react-helmet';

class Stylesheets extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const rules = this.props.overrides.filter(o => o.style_name === 'font-family');
    const names = rules.map(r => r.value.trim().replace(/ +/g, "+")).join('|');
    const href = "https://fonts.googleapis.com/css?family=" + names;

    return (
      <Helmet>
        { names &&
          <link href={href} rel="stylesheet" />
        }
      </Helmet>
    )
  }
}

export default Stylesheets;
