import React, { lazy, Suspense } from 'react';
import { BlockList } from './block';

class Row extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var props = this.props;

    return (
      <div className="block-row">
        <div className="row-body">
          <BlockList
            row={props.row}
            params={this.props.params}
            user={this.props.user}
            blocks={props.row.row_blocks}
            appAttributes={this.props.appAttributes}
            pageAttributes={this.props.pageAttributes}
            setNamedSignals={this.props.setNamedSignals}
            namedSignals={this.props.namedSignals}
            updateBlock={props.updateBlock} />
        </div>
      </div>
    )
  }
}

class Rows extends React.Component {
  toggleState(value) {
    return [true, 't', 'true', 'True'].includes(value)
  }

  render() {
    const rows = this.props.rows;
    const fluid = this.toggleState(this.props.page.attributes_hash.fluid);
    const containerClass = !fluid && (this.props.sectionId === 'rows') ? 'container' : 'fluid-container';
    const fixedFooter = this.props.sectionId === 'footer'
      && this.props.page.attributes_hash.fixed_footer
      ? 'fixed-footer' : '';
    const classes = [containerClass, fixedFooter, 'page-blocks', this.props.sectionId].join(' ');

    const rowsStyle = {};

    if (this.props.sectionId === 'rows' && this.props.page.attributes_hash.fixed_footer) {
      rowsStyle.paddingBottom = this.props.page.attributes_hash.fixed_footer;
    }

    return (
      <div className={classes} style={rowsStyle}>
        {rows && rows.map((o, i) =>
          <Row key={o.id}
            params={this.props.params}
            row={o}
            page_id={o.page_id}
            updateBlock={this.props.updateBlock}
            appAttributes={this.props.appAttributes}
            pageAttributes={this.props.pageAttributes}
            setNamedSignals={this.props.setNamedSignals}
            namedSignals={this.props.namedSignals}
            index={i}
            user={this.props.user}
        />)}
      </div>
    )
  }
}

export default Rows;
