// Utility functions

// fastest method for deep clone of pure json objects.
// (slice() is shallow clone.)
export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
};

export function mediaUrl(parts) {
  const url = parts.filter((obj) => obj ).join('')
  return url
}
