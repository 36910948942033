import React, { lazy, Suspense } from 'react';
import BlockType from './block/block_type';
import {mediaUrl} from './base/functions';
import Loading from './loading';

const BlockListEdit = lazy(() => import('./block_list_edit'));

class Block extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props
    const instance = props.block.instance

    const overlay = instance.attributes_hash.overlay
    const desktopWidth = instance.attributes_hash.width
    const mobileWidth = instance.attributes_hash.mobile_width
    const desktopClass = desktopWidth ? 'col-lg-'+desktopWidth : 'col-lg'
    const mobileClass = mobileWidth ? 'col-'+mobileWidth : 'col-12'
    const bootstrapClass = [desktopClass, mobileClass].join(' ')

    var cssClass = [
      bootstrapClass,
      'block',
      instance.block.block_type,
      instance.attributes_hash.css_class
    ].join(' ');

    const backgroundId = instance.attributes_hash.background_image
    const blockBackground = backgroundId ? mediaUrl(
      [this.props.user.cdn, '/files/image/', backgroundId]
    ) : null;

    const rendered = instance.rendered;

    return (
      <div className={cssClass}
        id={instance.attributes_hash.named_anchor}
        name={instance.attributes_hash.named_anchor}
        style={blockBackground && { backgroundImage : `url(${blockBackground})` }}
      >
        {overlay &&
          <div className='overlay' style={{backgroundColor: overlay}} />
        }
        { rendered ? <div className='block-body' dangerouslySetInnerHTML={{ __html: rendered }} />
          :
          <div className='block-body'>
            <BlockType
              block={props.block}
              params={props.params}
              user={props.user}
              update={props.update}
              appAttributes={this.props.appAttributes}
              pageAttributes={this.props.pageAttributes}
              setNamedSignals={this.props.setNamedSignals}
              namedSignals={this.props.namedSignals}
              edit={props.edit} />
          </div>
        }
      </div>
    )
  }
}

export class BlockList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var row = this.props.row
    var blocks = this.props.blocks

    if (this.props.edit) {
      return (
        <Suspense fallback={<Loading/>}>
          <BlockListEdit {...this.props} />
        </Suspense>
      )
    } else {
      return (
        <div className="row flex">
          {blocks.map((o, i) => <Block
            key={o.id}
            block={o}
            params={this.props.params}
            user={this.props.user}
            row_id={row.id}
            index={i}
            update={this.props.updateBlock}
            appAttributes={this.props.appAttributes}
            pageAttributes={this.props.pageAttributes}
            setNamedSignals={this.props.setNamedSignals}
            namedSignals={this.props.namedSignals}
          />)}
        </div>
      )
    }
  }
}
