import React from 'react';

// blocks
const EditorBlock = window.EditorBlock;
const MarkdownBlock = window.MarkdownBlock;
const CodeBlock = window.CodeBlock;
const NavigationBlock = window.NavigationBlock;
const ImageBlock = window.ImageBlock;
const FormBlock = window.FormBlock;
const SignInBlock = window.SignInBlock;
const PasswordResetBlock = window.PasswordResetBlock;
const SignUpBlock = window.SignUpBlock;
const UserStatusBlock = window.UserStatusBlock;
const SiteListBlock = window.SiteListBlock;
const MemberListBlock = window.MemberListBlock;
const HostnameListBlock = window.HostnameListBlock;
const FileLibraryBlock = window.FileLibraryBlock;
const AlbumLibraryBlock = window.AlbumLibraryBlock;
const ThemeListBlock = window.ThemeListBlock;
const AudioPlayerBlock = window.AudioPlayerBlock;
const AudioAlbumBlock = window.AudioAlbumBlock;
const ImageAlbumBlock = window.ImageAlbumBlock;
const IndexBlock = window.IndexBlock;
const PostBlock = window.PostBlock;
const MediaLinkSetBlock = window.MediaLinkSetBlock;

function BlockType(props) {
  const instance = props.block.instance
  switch (instance.block.block_type) {
    case 'rich_text':
      return <EditorBlock {...props} />
    case 'markdown':
      return <MarkdownBlock {...props} />
    case 'code':
      return <CodeBlock {...props} />
    case 'navigation':
      return <NavigationBlock {...props} />
    case 'image':
      return <ImageBlock {...props} />
    case 'form':
      return <FormBlock {...props} />
    case 'sign_in':
      return <SignInBlock {...props} />
    case 'password_reset':
      return <PasswordResetBlock {...props} />
    case 'sign_up':
      return <SignUpBlock {...props} />
    case 'user_status':
      return <UserStatusBlock {...props} />
    case 'site_list':
      return <SiteListBlock {...props} />
    case 'member_list':
      return <MemberListBlock {...props} />
    case 'hostname_list':
      return <HostnameListBlock {...props} />
    case 'file_library':
      return <FileLibraryBlock {...props} />
    case 'album_library':
      return <AlbumLibraryBlock {...props} />
    case 'audio_player':
      return <AudioPlayerBlock {...props} />
    case 'audio_album':
      return <AudioAlbumBlock {...props} />
    case 'image_album':
      return <ImageAlbumBlock {...props} />
    case 'theme_list':
      return <ThemeListBlock {...props} />
    case 'index':
      return <IndexBlock {...props} />
    case 'post':
      return <PostBlock {...props} />
    case 'media_link_set':
      return <MediaLinkSetBlock {...props} />
    default:
      return <div/>;
  }
}

export default BlockType
