import React, { lazy, Suspense } from 'react';
import Rows from './rows';
import Loading from './loading';

const RowsEdit = lazy(() => import('./rows_edit'));

class RowList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var page = this.props.page;
    var rows = this.props.rows;
    var header = this.props.header || null;
    var footer = this.props.footer || null;

    if (this.props.edit || this.props.admin){
      return (
        <Suspense fallback={<Loading/>}>
          <RowsEdit {...this.props} />
        </Suspense>
      )
    } else {
      return (
        <React.Fragment>
          {header &&
            <Rows
              sectionId='header'
              page={page}
              params={this.props.params}
              rows={header}
              updateBlock={this.updateBlock}
              appAttributes={this.props.appAttributes}
              pageAttributes={this.props.pageAttributes}
              setNamedSignals={this.props.setNamedSignals}
              namedSignals={this.props.namedSignals}
              user={this.props.user}
            />}
          <Rows
            sectionId='rows'
            page={page}
            params={this.props.params}
            rows={rows}
            updateBlock={this.updateBlock}
            appAttributes={this.props.appAttributes}
            pageAttributes={this.props.pageAttributes}
            setNamedSignals={this.props.setNamedSignals}
            namedSignals={this.props.namedSignals}
            user={this.props.user}
          />
          {footer &&
            <Rows
              sectionId='footer'
              page={page}
              params={this.props.params}
              rows={footer}
              updateBlock={this.updateBlock}
              appAttributes={this.props.appAttributes}
              pageAttributes={this.props.pageAttributes}
              setNamedSignals={this.props.setNamedSignals}
              namedSignals={this.props.namedSignals}
              user={this.props.user}
            />}
        </React.Fragment>
      );
    }
  }
}

export default RowList;
