import React from 'react';
import Icon from './icon';

class Button extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <button
        type={this.props.type}
        disabled={this.props.disabled}
        className={this.props.buttonClass}
        onClick={this.props.handleClick}
      >
        <Icon icon={this.props.icon} className={this.props.iconClass} />
        <span>{this.props.label}</span>
      </button>
    );
  }
}

export default Button;
