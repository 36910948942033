import React from 'react';
import Cookies from 'js-cookie'
import {Helmet} from 'react-helmet';
import '../vendor/sentry.js';
import RowList from './row_list';
import Styles from './styles.jsx';
import CurrentStyles from './admin/current_styles.jsx';
import Stylesheets from './stylesheets.jsx';
import ViewToggle from './admin/view_toggle.jsx';
import {clone, mediaUrl} from './base/functions';

const AdminNav = window.AdminNav;
const AjaxAPI = window.AjaxAPI;

class App extends React.Component {
  constructor(props) {
    super(props);
    if (typeof(currentPage) === "undefined") { return }

    const isMobile = window.innerWidth < 1092
    const userCookie = JSON.parse(Cookies.get('user'))
    //console.log(userCookie)
    const isBeta = !!(userCookie.roles.includes('beta'))
    const isAdmin = !!(
      userCookie.roles.includes('owner') ||
      userCookie.roles.includes('admin') ||
      userCookie.roles.includes('developer') ||
      userCookie.roles.includes('editor')
    )
    const isEditor = userCookie.admin_route ?
      !!(userCookie.site == 'admin' && isAdmin) : !!isAdmin
    const viewMode = window.localStorage.getItem('viewMode') || 'edit'
    //console.log(currentPage)
    this.state = {
      // current page
      page: currentPage,
      rows: currentPage.rows,
      headerObj: currentPage.header,
      footerObj: currentPage.footer,
      header: currentPage.header && currentPage.header.rows,
      footer: currentPage.footer && currentPage.footer.rows,
      attributes: attributes,
      params: params || {},

      theme: theme,
      themeOverrides: styleParameters,
      currentStyles: null,
      darkMode: false,

      // user
      user: userCookie,

      namedSignals: {},

      // admin data
      pages: pageList,
      pageGroups: [],
      headers: headerList,
      footers: footerList,
      edit: (viewMode == 'edit'),
      admin: isAdmin,
      editor: isEditor,
      demo: false,
      beta: isBeta
    };

    //renderThemeOverrides ([styleParameters])

    this.setRows = this.setRows.bind(this);
    this.setPages = this.setPages.bind(this);
    this.setPageGroups = this.setPageGroups.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setHeaders = this.setHeaders.bind(this);
    this.setFooters = this.setFooters.bind(this);
    this.setStyles = this.setStyles.bind(this);
    this.setAttributes = this.setAttributes.bind(this);
    this.setViewMode = this.setViewMode.bind(this);
    this.setDarkMode = this.setDarkMode.bind(this);
    this.setCurrentStyles = this.setCurrentStyles.bind(this);
    this.setThemeOverride = this.setThemeOverride.bind(this);
    this.setNamedSignals = this.setNamedSignals.bind(this);
    this.getNamedSignals = this.getNamedSignals.bind(this);

    // Make global
    window.appState = this
  }

  getNamedSignals() {
    return this.state.namedSignals
  }

  setNamedSignals(updates) {
    var namedSignals = clone(this.state.namedSignals)
    //console.log(namedSignals)

    updates.forEach(function(update, i) {
      if (!namedSignals[update.key]) {
        namedSignals[update.key] = {}
      }
      namedSignals[update.key][update.signal] = update.data
    })
    this.setState({ namedSignals: namedSignals })
  }

  setCurrentHeader(headerObj) {
    const _this = this;

    if (headerObj) {
      // get rows for header
      (new AjaxAPI).get('/pages/rows/header/'+headerObj.id, function(data){

        // update state
        _this.setState({headerObj: headerObj, header: data.rows })
      });
    } else {
      this.setState({headerObj: null, header: null })
    }
  }

  setCurrentFooter(footerObj) {
    const _this = this;

    if (footerObj) {
      // get rows for header
      (new AjaxAPI).get('/pages/rows/footer/'+footerObj.id, function(data){

        // update state
        _this.setState({footerObj: footerObj, footer: data.rows })
      });
    } else {
      this.setState({footerObj: null, footer: null })
    }
  }

  setPage(page, current) {
    if (current) {
      const headerId = this.state.headerObj && this.state.headerObj.id;
      if (page.header_id != headerId) {
        var header = this.state.headers.find(function(header) {
          return header.id === page.header_id;
        });

        this.setCurrentHeader(header);
      }

      const footerId = this.state.footerObj && this.state.footerObj.id;
      if (page.footer_id != footerId) {
        var footer = this.state.footers.find(function(footer) {
          return footer.id === page.footer_id;
        });

        this.setCurrentFooter(footer);
      }
    }

    var pages = clone(this.state.pages);
    var index = null;

    pages.forEach(function(p, i) {
      if (p.id == page.id) { index = i; }
    });

    if (index  != null) { pages[index] = page; }

    this.setState({pages: pages })
  }

  setRows(rows, section) {
    this.setState({[section]: rows })
  }

  setPages(pages) {
    this.setState({pages: pages })
  }

  setPageGroups(pageGroups) {
    this.setState({pageGroups: pageGroups })
  }

  setHeaders(headers) {
    this.setState({headers: headers })
  }

  setFooters(footers) {
    this.setState({footers: footers })
  }

  setStyles(styles) {
    this.setState({styles: styles })
  }

  setAttributes(attributes) {
    this.setState({attributes: attributes })
  }

  setViewMode(viewMode) {
    this.setState({edit: viewMode })
    window.localStorage.setItem('viewMode', (viewMode ? 'edit' : 'view'));
  }

  setCurrentStyles(id) {
    this.setState({currentStyles: id })
  }

  setDarkMode(mode) {
    this.setState({darkMode: mode })
  }

  setThemeOverride (override) {
    var overrides = clone(this.state.themeOverrides);
    var index = null;

    overrides.forEach(function(o, i) {
      if (o.name == override.name) {
        index = i;
      }
    });

    if (index == null) {
      overrides.push(override);
    } else {
      overrides[index] = override;
    }

    this.setState({themeOverrides: overrides })
  }

  render() {
    if (!this.state) { return null; }

    const editMode = this.state.editor && this.state.edit
    const adminOnly = this.state.admin && !this.state.editor
    const darkMode = editMode && this.state.darkMode
    const adminPanelMode = adminOnly || editMode
    const pageClass = this.state.page.attributes_hash.css_class
    const backgroundId = this.state.page.attributes_hash.background_image
    const pageBackground = backgroundId ? mediaUrl(
      [this.state.user.cdn, '/files/image/', backgroundId]
    ) : null;
    const wrapperClass = [
      (adminPanelMode ? 'admin-panel' : ''),
      (editMode ? 'edit' : '')
    ].join(' ');
    const theme = this.state.user.admin_route ? 'admin' : this.state.theme
    const themeClass = [
      'body',
      theme,
      pageClass,
      (adminPanelMode ? 'admin-panel' : ''),
      (editMode ? 'edit' : ''),
      (darkMode ? 'light' : 'dark')
    ].join(' ');
    const bodyClass = 'page-body ' + (editMode ? 'edit' : '');

    return (
      <React.Fragment>
        {adminPanelMode &&
          <div className='admin-sidebar d-none d-lg-block' id='admin-sidebar'>
          <div className='wrapper'>
            <AdminNav
              currentPage={this.state.page}
              pages={this.state.pages}
              headers={this.state.headers}
              footers={this.state.footers}
              setPages={this.setPages}
              setPage={this.setPage}
              setHeaders={this.setHeaders}
              setFooters={this.setFooters}
              themeOverrides={this.state.themeOverrides}
              currentStyles={this.state.currentStyles}
              setThemeOverride={this.setThemeOverride}
              setAttributes={this.setAttributes}
              attributes={this.state.attributes}
              darkMode={this.state.darkMode}
              setDarkMode={this.setDarkMode}
              demo={this.state.demo}
              beta={this.state.beta}
              user={this.state.user}
            />
          </div></div>
        }
        <div id='page-body' className={wrapperClass}>
          {this.state.editor &&
            <div id='view-toggle' className={(darkMode ? 'light' : 'dark')}>
              <ViewToggle
                edit={this.state.edit}
                setViewMode={this.setViewMode}
              />
            </div>
          }
          <div id='content-body'
               className={themeClass}
          >
            <Stylesheets overrides={this.state.themeOverrides} />
            <Styles overrides={this.state.themeOverrides} />
            {adminPanelMode &&
              <CurrentStyles setCurrentStyles={this.setCurrentStyles} />
            }
            <div className='content'
              style={pageBackground && { backgroundImage : `url(${pageBackground})` }}
            >
              <div className={bodyClass}>
                <RowList
                  page={this.state.page}
                  pageAttributes={this.state.page.attributes_hash}
                  params={this.state.params}
                  rows={this.state.rows}
                  headerObj={this.state.headerObj}
                  footerObj={this.state.footerObj}
                  header={this.state.header}
                  footer={this.state.footer}
                  edit={editMode}
                  admin={adminPanelMode}
                  demo={this.state.demo}
                  user={this.state.user}
                  setRows={this.setRows}
                  appAttributes={this.state.attributes}
                  setNamedSignals={this.setNamedSignals}
                  namedSignals={this.state.namedSignals}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default App
