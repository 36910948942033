import React from 'react';
import Button from '../button';

class ViewToggle extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const viewMode = !this.props.edit;

    this.props.setViewMode(viewMode);
  }

  render() {
    return (
      <Button
        buttonClass='btn view-toggle'
        icon='edit-view'
        handleClick={this.handleClick}
      >
      </Button>
    );
  }
}

export default ViewToggle;
