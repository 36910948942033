import React from 'react';

function FontFamilyImport(props) {
  const value = props.value.trim();
  const urlValue = value.replace(/ +/g, "+");

  return(
    <React.Fragment>
      { urlValue &&
        "@import url('https://fonts.googleapis.com/css?family=" + urlValue + "');"
      }
    </React.Fragment>
  );
}

function FontFamilyStyle(props) {
  const value = props.value.trim();

  return(
    <React.Fragment>
      {"font-family: '" + value + "', sans-serif;"}
    </React.Fragment>
  );
}

function TextWeightStyle(props) {
  return(<React.Fragment>{"font-weight: " + props.value + ";"}</React.Fragment>);
}

function TextStyleStyle(props) {
  return (<React.Fragment>{"font-style: " + props.value + ";"}</React.Fragment>);
}

function TextSizeStyle(props) {
  return (<React.Fragment>{"font-size: " + props.value + "rem;"}</React.Fragment>);
}

function TextTransformStyle(props) {
  return (<React.Fragment>{"text-transform: " + props.value + ";"}</React.Fragment>);
}

function TextLetterSpacingStyle(props) {
  return (<React.Fragment>{"letter-spacing: " + props.value + "em;"}</React.Fragment>);
}

function TextLineHeightStyle(props) {
  return (<React.Fragment>{"line-height: " + props.value + ";"}</React.Fragment>);
}

function ColorStyle(props) {
  const rgba = props.value
  return (<React.Fragment>{"color: " + rgba + ";"}</React.Fragment>);
}

function BackgroundColorStyle(props) {
  const rgba = props.value
  return (<React.Fragment>{"background-color: " + rgba + ";"}</React.Fragment>);
}

function BorderStyle(props) {
  return (<React.Fragment>{"border-style: " + props.value + ";"}</React.Fragment>);
}

function BorderWidthStyle(props) {
  return (<React.Fragment>{"border-width: " + props.value + "px;"}</React.Fragment>);
}

function BorderRadiusStyle(props) {
  return (<React.Fragment>{"border-radius: " + props.value + "px;"}</React.Fragment>);
}

function BorderColorStyle(props) {
  const rgba = props.value
  return (<React.Fragment>{"border-color: " + rgba + ";"}</React.Fragment>);
}

function TextDecorationStyle(props) {
  return (<React.Fragment>{"text-decoration-line: " + props.value + ";"}</React.Fragment>);
}


var StyleMap = {
  'font-family': FontFamilyStyle,
  'font-weight': TextWeightStyle,
  'font-style': TextStyleStyle,
  'font-size': TextSizeStyle,
  'text-transform': TextTransformStyle,
  'letter-spacing': TextLetterSpacingStyle,
  'line-height': TextLineHeightStyle,
  'color': ColorStyle,
  'background-color': BackgroundColorStyle,
  'border-style': BorderStyle,
  'border-width': BorderWidthStyle,
  'border-radius': BorderRadiusStyle,
  'border-color': BorderColorStyle,
  'text-decoration-line': TextDecorationStyle
}

function StyleRule(props) {
  const StyleName = StyleMap[props.style_name];
  const selectors = props.selector.split(',')
  const selectorString = selectors.map(function(s){
    return '#app-body #page-body ' + s
  }).join(', ')

  if (StyleName) {
    return (
      <React.Fragment>
        {selectorString + " {"}
          <StyleName {...props} />
        {"}"}
      </React.Fragment>
    )
  } else {
    return(null);
  }
}

class Styles extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <style>
        {this.props.overrides.map(o => <StyleRule key={o.name} {...o} /> )}
      </style>
    )
  }
}

export default Styles;
