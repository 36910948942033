/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import React from 'react';
const ReactDOM = require('react-dom');
import App from '../components/app.jsx';

// Debugging function to enumrate found style sheets
function getStyleSheets() {
  for(var i = 0; i < document.styleSheets.length; i++) {
    var sheet = document.styleSheets[i];
  }
}
//getStyleSheets();

var appContainer = document.getElementById('app-body');

ReactDOM.render(<App/>, appContainer);


function handleFirstTab(e) {
    if (e.keyCode === 9) { // the "I am a keyboard user" key
        document.body.classList.add('user-is-tabbing');
        window.removeEventListener('keydown', handleFirstTab);
    }
}

window.addEventListener('keydown', handleFirstTab);
