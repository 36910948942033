import React from 'react';

class CurrentStyles extends React.Component {
  constructor(props) {
    super(props);

  }

  componentDidMount(prevProps) {
    this.props.setCurrentStyles('current-styles')
  }

  render() {
    return (
      <div className='content block-body' id='current-styles-page'>
        <p id='current-styles-text'>&nbsp;</p>
        <a id='current-styles-link-text'>&nbsp;</a>
        <a className='hover' id='current-styles-link-text-hover'>&nbsp;</a>
        <h1 id='current-styles-heading-1'>&nbsp;</h1>
        <h2 id='current-styles-heading-2'>&nbsp;</h2>
        <h3 id='current-styles-heading-3'>&nbsp;</h3>
        <blockquote id='current-styles-blockquote'>&nbsp;</blockquote>
        <div className='block title' id='current-styles-title'>&nbsp;</div>
        <div className='CodeMirror' id='current-styles-code'>&nbsp;</div>
        <div className='navbar-nav'>
          <div className='nav-item nav-link' id='current-styles-nav'>&nbsp;</div>
          <div className='nav-item nav-link hover' id='current-styles-nav-hover'>&nbsp;</div>
          <div className='nav-item' id='current-styles-nav-style'>&nbsp;</div>
          <div className='nav-item hover' id='current-styles-nav-style-hover'>&nbsp;</div>
        </div>
        <button className='btn' id='current-styles-button'>&nbsp;</button>
        <button className='btn' id='current-styles-button-style'>&nbsp;</button>
        <button className='btn.hover' id='current-styles-button-style-hover'>&nbsp;</button>
      </div>
    )
  }
}

export default CurrentStyles
