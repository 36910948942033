import React from 'react';
import PlusIcon from 'react-material-icon-svg/dist/PlusIcon';
import DeleteForeverIcon from 'react-material-icon-svg/dist/DeleteForeverIcon';
import SettingsIcon from 'react-material-icon-svg/dist/SettingsIcon';
import CloseIcon from 'react-material-icon-svg/dist/CloseIcon';
import DragIcon from 'react-material-icon-svg/dist/DragIcon';
import DragVerticalIcon from 'react-material-icon-svg/dist/DragVerticalIcon';
import ViewListIcon from 'react-material-icon-svg/dist/ViewListIcon';
import ImageIcon from 'react-material-icon-svg/dist/ImageIcon';
import VolumeHighIcon from 'react-material-icon-svg/dist/VolumeHighIcon';
import FormatPilcrowIcon from 'react-material-icon-svg/dist/FormatPilcrowIcon';
import FileOutlineIcon from 'react-material-icon-svg/dist/FileOutlineIcon';
import EyeIcon from 'react-material-icon-svg/dist/EyeIcon';
import PencilIcon from 'react-material-icon-svg/dist/PencilIcon';
import ChevronLeftIcon from 'react-material-icon-svg/dist/ChevronLeftIcon';
import ChevronRightIcon from 'react-material-icon-svg/dist/ChevronRightIcon';
import TextboxIcon from 'react-material-icon-svg/dist/TextboxIcon';
import TextIcon from 'react-material-icon-svg/dist/TextIcon';
import CheckboxMarkedOutlineIcon from 'react-material-icon-svg/dist/CheckboxMarkedOutlineIcon';
import RadioboxMarkedIcon from 'react-material-icon-svg/dist/RadioboxMarkedIcon';
import WindowMaximizeIcon from 'react-material-icon-svg/dist/WindowMaximizeIcon';
import WindowMinimizeIcon from 'react-material-icon-svg/dist/WindowMinimizeIcon';
import ContentSaveIcon from 'react-material-icon-svg/dist/ContentSaveIcon';
import LoginIcon from 'react-material-icon-svg/dist/LoginIcon';
import TextboxPasswordIcon from 'react-material-icon-svg/dist/TextboxPasswordIcon';
import AccountPlusIcon from 'react-material-icon-svg/dist/AccountPlusIcon';
import PulseIcon from 'react-material-icon-svg/dist/PulseIcon';
import WebIcon from 'react-material-icon-svg/dist/WebIcon';
import AccountMultipleIcon from 'react-material-icon-svg/dist/AccountMultipleIcon';
import LibraryIcon from 'react-material-icon-svg/dist/LibraryIcon';
import FolderMultipleIcon from 'react-material-icon-svg/dist/FolderMultipleIcon';
import ViewDashboardIcon from 'react-material-icon-svg/dist/ViewDashboardIcon';
import PlayIcon from 'react-material-icon-svg/dist/PlayIcon';
import SkipNextIcon from 'react-material-icon-svg/dist/SkipNextIcon';
import SkipPreviousIcon from 'react-material-icon-svg/dist/SkipPreviousIcon';
import PauseIcon from 'react-material-icon-svg/dist/PauseIcon';
import DatabaseSearchIcon from 'react-material-icon-svg/dist/DatabaseSearchIcon';
import CodeBracesIcon from 'react-material-icon-svg/dist/CodeBracesIcon';
import RssFeedIcon from 'react-material-icon-svg/dist/RssBoxIcon';
import LinkVariantIcon from 'react-material-icon-svg/dist/LinkVariantIcon';
import MarkdownIcon from 'react-material-icon-svg/dist/MarkdownIcon';
import PublishIcon from 'react-material-icon-svg/dist/PublishIcon';

// SVG files
//import EditViewSvg from 'svg-inline-loader!../svg/edit-view.svg';

var Icons = {
  'add': PlusIcon,
  'delete': DeleteForeverIcon,
  'close': CloseIcon,
  'settings': SettingsIcon,
  'edit': PencilIcon,
  'view': EyeIcon,
  'drag': DragIcon,
  'drag-vertical': DragVerticalIcon,
  'list': ViewListIcon,
  'page': FileOutlineIcon,
  'chevron-left': ChevronLeftIcon,
  'chevron-right': ChevronRightIcon,
  'maximize': WindowMaximizeIcon,
  'minimize': WindowMinimizeIcon,
  'save': ContentSaveIcon,
  'publish': PublishIcon,

  // blocks
  'rich_text': FormatPilcrowIcon,
  'markdown': MarkdownIcon,
  'code': CodeBracesIcon,
  'navigation': ViewListIcon,
  'image': ImageIcon,
  'audio': VolumeHighIcon,
  'form': TextboxIcon,
  'sign_in': LoginIcon,
  'password_reset': TextboxPasswordIcon,
  'sign_up': AccountPlusIcon,
  'user_status': PulseIcon,
  'site_list': WebIcon,
  'site': WebIcon,
  'member_list': AccountMultipleIcon,
  'hostname_list': WebIcon,
  'site_edit': WebIcon,
  'file_library': LibraryIcon,
  'album_library': LibraryIcon,
  'audio_player': VolumeHighIcon,
  'audio_album': FolderMultipleIcon,
  'image_album': FolderMultipleIcon,
  'theme_list': ViewDashboardIcon,
  'index': DatabaseSearchIcon,
  'post': RssFeedIcon,
  'media_link_set': LinkVariantIcon,

  // form fields
  'text': TextboxIcon,
  'text_area': TextIcon,
  'select': ViewListIcon,
  'checkbox': CheckboxMarkedOutlineIcon,
  'radio': RadioboxMarkedIcon,

  // player
  'play': PlayIcon,
  'pause': PauseIcon,
  'skip-next': SkipNextIcon,
  'skip-previous': SkipPreviousIcon,

  'edit-view': EyeIcon //EditViewSvg
}

function Icon(props) {
  const IconName = Icons[props.icon];

  if (IconName) {
    return (
        <IconName className={props.iconClass} />
    );
  } else {
    return null;
  }
}

export default Icon;
